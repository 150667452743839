import { FunctionComponent, useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { gzipSync } from 'zlib'
import { StateContext } from '../context'
import Custom from './custom'
import ShareButton from './share-button'
import WordList from './word-list'

const Fail: FunctionComponent = () => {
	return <></>
	// const state = useContext(StateContext)
	// const [show, setShow] = useState(false)
	// const handleClose = () => setShow(false)
	// return (
	// 	<>
	// 		<Modal show={show} onHide={handleClose}>
	// 			<Modal.Header closeButton>
	// 				{state.boards.filter(board => !board.active).length}/5 Words Guessed
	// 			</Modal.Header>
	// 			<Modal.Body>
	// 				<WordList />
	// 				<Custom type="button" onPlay={handleClose}>
	// 					Play Custom
	// 				</Custom>
	// 				<span>
	// 					{gzipSync(
	// 						JSON.stringify({
	// 							state: state.boards.map(board =>
	// 								board.guesses.map(guess =>
	// 									guess
	// 										.map(letter => `${letter.color}${letter.letter}`)
	// 										.reduce((prev, cur) => prev + cur),
	// 								),
	// 							),
	// 							seed: state.seed,
	// 						}),
	// 						{
	// 							level: 9,
	// 						},
	// 					).toString('base64')}
	// 				</span>
	// 			</Modal.Body>
	// 		</Modal>
	// 	</>
	// )
}

export default Fail
