import { FunctionComponent, useContext, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { gzipSync } from 'zlib'
import { StateContext } from '../context'
import Custom from './custom'
import ShareButton from './share-button'
import WordList from './word-list'

const cryptoKey = crypto.subtle.importKey(
	'jwk',
	{
		key_ops: ['encrypt'],
		ext: true,
		kty: 'RSA',
		n: 'uhvD3AFAb-iJiXcM-WDCPqYRw2NJXG7Nt32Iea69o41fTCDnEMPHutLtAJSlQDaMtNdwkXvu0KWdZMhbLx_WhDhxQySQbxrNf0mfzsHPOgBjPhgnu4HaYY-Vm_2eahSJIpLwzq24cZCUKxYOGsiIG86yyXhoJLdAbKH7wQMHgoTJfRsljp2fULXqAjM7tvWfNiR5hkmAb39YttT2cJgXNAuv58gHkeQnu5V1_TFVJ1i_aqJSC52I8x7mjCRO5d8gAr4kCWgzurR8t0qQZCVD1NHTiTs2eXeCY4KY8IXIucZTm77H8h-pvEIuJqczBSI9hsS5jT_LEJeXCXjxdC4sH-51mOBCFAdZ3Adzd9kS3fxfMcs3oT0DJnXdQIpkU86clWu3l-OVyt3BNdkd-9iOdHyETJQJKVEWY_4bJNvRIbIUW9Tkx8BFlwpOrWvpt6M-jfaCv5Pac4bOjetWzX80DtaNyKQU_NhvHsaMtV3fUC6f4_saIBWRKwMh4uPgSbl6F6nlte46kx3f60QDdSeZUuHnI1a4Zen9GpE6eYPHDKd_tF_9VJ3TvSTdouTznUrIXc4jBqxfY41boVHRluxBdoJJDM7_ZWRIzgUwwUZBD8WU3AccjOVd-uEfJlgLqSdggXImHrcS8wU6HZBY1WFrCMrhZzZ_rPG16cBR_4Zntbk',
		e: 'AQAB',
		alg: 'RSA-OAEP-256',
	},
	{
		name: 'RSA-OAEP',
		hash: 'SHA-256',
	},
	false,
	['encrypt'],
)

const Complete: FunctionComponent = () => {
	const state = useContext(StateContext)
	const [show, setShow] = useState(false)
	const [data, setData] = useState('Loading...')

	console.log(
		`Drawing dialog with state = ${state}, show = ${show}, data = ${data}`,
	)

	const handleClose = () => setShow(false)

	if (
		(state.wantsCompleteDialog || state.wantsFailDialog) &&
		!(
			window.location.hash.startsWith('#seed') &&
			window.location.hash.slice(5) !== state.seed
		)
	) {
		console.log('Clearing wanted dialog')
		state.wantsCompleteDialog = false
		state.wantsFailDialog = false
		setShow(true)
		cryptoKey
			.then(key => {
				console.log('zipping data and stuff')

				const gzipped = gzipSync(
					JSON.stringify({
						state: JSON.parse(localStorage.getItem('customState')!),
						swaps: JSON.parse(localStorage.getItem('customSwaps')!),
						seed: localStorage.getItem('customSeed')!,
						extra: JSON.parse(localStorage.getItem('extraData')!)[
							localStorage.getItem('customSeed')!
						],
					}),
					{
						level: 9,
					},
				)
				const chunks = []

				for (let i = 0; i < gzipped.length; i += 400) {
					chunks.push(gzipped.subarray(i, i + 400))
				}

				return Promise.all(
					chunks.map(chunk =>
						crypto.subtle.encrypt(
							{
								name: 'RSA-OAEP',
							},
							key,
							chunk,
						),
					),
				)
			})
			.then(data => {
				console.log('i love base64')
				setData(
					data.map(chunk => Buffer.from(chunk).toString('base64')).join('!'),
				)
			})
	}

	return (
		<>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					{state.boards.filter(board => !board.active).length}/5 Words Guessed
				</Modal.Header>
				<Modal.Body>
					<WordList />
					{
						<>
							<Form.Control type="text" id="export" disabled value={data} />
							<Button
								variant="secondary"
								onClick={() => {
									navigator.clipboard.writeText(data)
								}}
							>
								Copy
							</Button>
						</>
					}
					<br />
					<Custom type="button" onPlay={handleClose}>
						New Game
					</Custom>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default Complete
