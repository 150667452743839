import { FunctionComponent, useEffect, useState } from 'react'
import GameState from '../state'
declare var state: GameState

const WORD_MINS = 2
const EXTRA_MINS = 10

function stringifyTime(time: number) {
	time = Math.max(time, 0)
	time = Math.ceil(time / 1e3)

	return `${Math.floor(time / 60)}:${(time % 60).toString().padStart(2, '0')}`
}

export type TimerState = {
	active: 'word' | 'extra'
	wordTime: number
	extraTime: number
}

export function resetTimer() {
	localStorage.setItem('startTime', Date.now().toString())
	localStorage.setItem('extraTime', (EXTRA_MINS * 60 * 1e3).toString())
}

export function getTimerState(): TimerState {
	if (!state.active()) {
		return {
			active: 'word',
			wordTime: WORD_MINS * 60 * 1e3,
			extraTime: EXTRA_MINS * 60 * 1e3,
		}
	}
	const now = Date.now()
	const startTime = parseInt(localStorage.getItem('startTime')!)
	const extraTime = parseInt(localStorage.getItem('extraTime')!)
	const wordTime = startTime + WORD_MINS * 60 * 1e3 - now

	if (wordTime > 0) {
		return {
			active: 'word',
			wordTime,
			extraTime: Math.max(0, extraTime),
		}
	} else {
		return {
			active: 'extra',
			wordTime: 0,
			extraTime: Math.max(0, extraTime + wordTime),
		}
	}
}

export function advanceTimer() {
	const state = getTimerState()
	const startTime = parseInt(localStorage.getItem('startTime')!)
	const usedExtraTime =
		parseInt(localStorage.getItem('extraTime')!) - state.extraTime
	localStorage.setItem(
		'startTime',
		(
			startTime -
			state.wordTime +
			usedExtraTime +
			WORD_MINS * 60 * 1e3
		).toString(),
	)
	console.log(startTime)
	localStorage.setItem('extraTime', state.extraTime.toString())
}

const Timer: FunctionComponent<{ large?: true }> = ({ large }) => {
	const [curTime, setCurTime] = useState(Date.now())
	const state = getTimerState()

	useEffect(() => {
		const interval = setInterval(() => setCurTime(Date.now()), 100)

		return () => clearInterval(interval)
	}, [])

	return (
		<div className="timer" style={large && { fontSize: '1.5em' }}>
			<div className="timer-title">YOUR TIME</div>
			<div className="timers">
				<span
					className={`word-time ${
						state.active === 'word'
							? `active-timer ${
									state.wordTime > 60 * 1e3
										? 'high-time'
										: state.wordTime > 30 * 1e3
										? 'mid-time'
										: 'low-time'
							  }`
							: 'zero-time'
					}`}
				>
					{stringifyTime(state.wordTime)}
				</span>
				+
				<span
					className={`extra-time ${
						state.extraTime === 0
							? 'zero-time'
							: state.active === 'extra'
							? `active-timer ${
									state.extraTime > 5 * 60 * 1e3
										? 'high-time'
										: state.extraTime > 2 * 60 * 1e3
										? 'mid-time'
										: 'low-time'
							  }`
							: ''
					}`}
				>
					{stringifyTime(state.extraTime)}
				</span>
			</div>
		</div>
	)
}

export default Timer
