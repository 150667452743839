import {
	FormEvent,
	FunctionComponent,
	useContext,
	useEffect,
	useState,
} from 'react'
import { Button, Form, Modal, Nav } from 'react-bootstrap'
import { setGameState } from '..'
import { StateContext } from '../context'
import GameState, { dailyNumber, getDailySeed } from '../state'

function tryParseURL() {
	const params = new URLSearchParams(window.location.hash.slice(1))

	if (params.has('seed')) {
		return {
			seed: params.get('seed') as string,
			blues: params.get('blues') !== '0',
			swaps: params.get('swaps') !== '0',
		}
	} else {
		return null
	}
}

const PastDaily: FunctionComponent<{ disabled: boolean | undefined }> = ({
	disabled,
}) => {
	const items = []
	for (let i = 1; i < dailyNumber(); i++) {
		items.push(
			<option key={i} value={i}>
				Daily Sortdle #{i}
			</option>,
		)
	}

	return (
		<Form.Select
			id="past-daily-word-select"
			disabled={disabled}
			onClick={() => {
				if (!disabled) {
					;(
						document.querySelector('#past-daily-word') as HTMLInputElement
					).checked = true
				}
			}}
		>
			{items}
		</Form.Select>
	)
}

function validateSeed(seed: string): string | null {
	seed = seed.trim()
	const primes = [2, 3, 5, 7, 11, 13, 17, 23]
	const chars = 'abcdefghijklmnopqrstuvwxyz+!?'
	if (seed.length !== 8 && (seed.length !== 9 || !chars.includes(seed[8]))) {
		return 'This seed is invalid.'
	}

	let sum = 0
	for (let i = 0; i < 8; i++) {
		let num = parseInt(seed[i])
		if (isNaN(num)) return 'This seed is invalid.'

		sum += primes[i] ** (num + 1)
	}

	if (seed.length === 9 && seed[8].toLowerCase() === chars[sum % 29]) {
		return null
	} else {
		return `This seed's check character is '${chars[sum % 29]}'`
	}
}

let isLoad = true

const Custom: FunctionComponent<{
	type: 'nav' | 'button' | 'onLoad'
	onPlay?: (() => any) | null | undefined
}> = ({ type, onPlay, children }) => {
	const state = useContext(StateContext)
	const [show, setShow] = useState(false)
	const [link, setLink] = useState<null | string>(null)
	let defaultSeed = ''

	if (
		window.location.hash.startsWith('#seed') &&
		window.location.hash.slice(5) !== state.seed
	) {
		defaultSeed = window.location.hash.slice(5)
	}
	const [invalid, setInvalid] = useState(validateSeed(defaultSeed))
	if (isLoad && !window.location.hash.startsWith('#view')) {
		isLoad = false
		if (
			(window.location.hash.startsWith('#seed') && defaultSeed) ||
			localStorage.getItem('customSeed') == null ||
			localStorage.getItem('customSeed') ===
				'__DO_NOT_USE_THIS_SEED_THIS_IS_THE_HACKIEST_THING_YET'
		) {
			setShow(true)
		}
		// GameState.load('custom').then(state => {
		// 	if (!state.active()) {
		// 		setShow(true)
		// 	}
		// })
	}

	const updateLink = () => {
		setLink(
			`${window.location.href.split('#')[0]}#seed=${encodeURIComponent(
				(
					document.querySelector('#seed-input') as HTMLInputElement
				).value.toLowerCase(),
			).trim()}&blues=${
				(document.querySelector('#blue-letter-toggle') as HTMLInputElement)
					.checked
					? 1
					: 0
			}&swaps=${
				(document.querySelector('#swap-toggle') as HTMLInputElement).checked
					? 1
					: 0
			}`,
		)
	}

	const handleClose = () => setShow(false)

	const handleShow = () => setShow(true)
	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		const { default: GameState } = await import('../state')

		const settings = {
			blues: (document.querySelector('#blue-letter-toggle') as HTMLInputElement)
				.checked,
			swaps: (document.querySelector('#swap-toggle') as HTMLInputElement)
				.checked,
		}

		localStorage.setItem(
			'extraData',
			JSON.stringify({
				...JSON.parse(localStorage.getItem('extraData') ?? '{}'),
				[state.seed]: state.guesses().join(''),
			}),
		)

		setGameState(
			await GameState.custom(
				(document.querySelector('#seed-input') as HTMLInputElement).value
					.toLowerCase()
					.trim(),
			),
		)

		if (onPlay) {
			onPlay()
		}
		history.replaceState(null, '', `${window.location.pathname}`)
		handleClose()
	}

	let defaultSettings = {
		blues: true,
		swaps: true,
	}

	return (
		<>
			{type === 'button' && <Button onClick={handleShow}>{children}</Button>}
			<Modal show={show} onHide={type === 'onLoad' ? undefined : handleClose}>
				<Modal.Header closeButton={type !== 'onLoad'}>
					Sortdle Global Championships
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleSubmit}>
						<Form.Group>
							<Form.Label as="h3">Words</Form.Label>
							<Form.Check
								name="words"
								id="random-word"
								type="radio"
								label="Random"
								onClick={() => setLink(null)}
								disabled
							/>
							<Form.Check
								name="words"
								id="past-daily-word"
								type="radio"
								disabled
								onClick={() => setLink(null)}
								label={
									<>
										Past Daily: <PastDaily disabled />
									</>
								}
							/>
							<Form.Check
								name="words"
								id="seeded-word"
								type="radio"
								defaultChecked
								label={
									<>
										Seed:{' '}
										<Form.Control
											type="text"
											id="seed-input"
											onChange={el => {
												setInvalid(
													validateSeed((el.target as HTMLInputElement).value),
												)
											}}
											isInvalid={invalid != null}
											// disabled={type === 'onLoad'}
											defaultValue={defaultSeed}
										/>
										{invalid && (
											<span style={{ color: 'red', fontSize: '0.75em' }}>
												{invalid}
											</span>
										)}
									</>
								}
							/>
						</Form.Group>

						<Form.Group>
							<Form.Label as="h3">Rules</Form.Label>
							<Form.Check
								id="blue-letter-toggle"
								type="switch"
								label="Blue Letters"
								defaultChecked
								disabled
								onClick={() => {
									if (link) updateLink()
								}}
							/>
							<Form.Check
								id="swap-toggle"
								type="switch"
								label="Swaps"
								defaultChecked
								disabled
								onClick={() => {
									if (link) updateLink()
								}}
							/>
						</Form.Group>

						<Form.Group>
							<Button
								type="submit"
								variant="success"
								disabled={invalid != null}
							>
								Create
							</Button>
							{type !== 'onLoad' && (
								<Button onClick={handleClose} variant="danger">
									Cancel
								</Button>
							)}
						</Form.Group>
					</Form>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default Custom
