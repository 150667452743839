import './stylesheets/index.scss'
import { render } from 'react-dom'
import GameState from './state'
import { LETTERS } from './constants'
import Game from './components/game'
import { advanceTimer, getTimerState } from './components/timer'
;(globalThis as any).state = null
declare var state: GameState

export function setGameState(newState: GameState) {
	state = newState
	update()
}

export async function loadState() {
	const { default: GameState } = await import('./state')

	if (window.location.hash.startsWith('#view')) {
		state = await GameState.view(window.location.hash.slice(5))
	} else if (
		localStorage.getItem('customSeed') != null &&
		localStorage.getItem('customSeed') !==
			'__DO_NOT_USE_THIS_SEED_THIS_IS_THE_HACKIEST_THING_YET'
	) {
		state = await GameState.load('custom')
		if (!state.active() && state.seed) {
			state.dialogCheck()
		}
	} else {
		state = await GameState.custom(
			'__DO_NOT_USE_THIS_SEED_THIS_IS_THE_HACKIEST_THING_YET',
		)
	}

	update()
}

loadState()
window.addEventListener('hashchange', loadState)

export function update() {
	state.save()
	render(
		<Game state={state} />,
		document.querySelector('#app') as HTMLDivElement,
	)
}

document.addEventListener('keypress', event => {
	if (document.activeElement?.tagName === 'INPUT') return
	if (LETTERS.has(event.key.toLowerCase()) && state.curGuess.length < 5) {
		state.curGuess += event.key.toLowerCase()
		update()
	}
})
document.addEventListener('keydown', event => {
	if (document.activeElement?.tagName === 'INPUT') return
	if (event.key === 'Backspace') {
		if (event.ctrlKey) {
			state.curGuess = ''
		} else {
			state.curGuess = state.curGuess.slice(0, state.curGuess.length - 1)
		}
		update()
	} else if (event.code === 'Enter') {
		state.submit()
		update()
	}
})

setInterval(() => {
	if (state !== null) {
		const tState = getTimerState()
		if (tState.wordTime <= 0 && tState.extraTime <= 0) {
			if (!state.submit()) {
				state.curGuess = '     '
				state.submit(true)
			}
			update()
		}
	}
}, 100)
